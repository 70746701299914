<template>
  <svg
    v-if="iconPath != null"
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
	class="svg-inline--fa fa-th-large"
  >
    <path fill="currentColor" :d="iconPath"></path>
  </svg>
</template>

<script>
import * as mdiIcons from "@mdi/js";

export default {
  name: "MyIcon",
  components: {},
  props: {
    name: {
      type: String,
      default: null,
    }
  },
  watch: {
    name: {
      handler: function (nameSelected) {
		let self = this;
		nameSelected = nameSelected.trim();
        console.log("nameSelected", nameSelected);
		// console.log('mdiIcons[nameSelected]', mdiIcons['xxxxxxxxxxxxx']);

		self.iconPath = mdiIcons['mdiAlert'];
		if(mdiIcons[nameSelected]) {
			self.iconPath = mdiIcons[nameSelected];
		} else {
			console.info(`icon ${nameSelected} not found`);
		}

		var values = self.iconPath.split('L').join(' ').split('M').join(' ').split('S').join(' ').split('A').join(' ').split('V').join(' ').split('H').join(' ').split('C').join(' ').split('Z').join(' ').split(',').join(' ').split('z').join('').split(' ');
		console.log('values', values);
		values = values.map(Number);
		console.log('values', ...values);
		var max = Math.max(...values);//Math.max.apply(null, ...values);
		// const max = values.reduce((a, b) => { return Math.max(a, b) });

		// var w = self.iconPath.getBoundingClientRect().width;

		var min = Math.min.apply( Math, values );
		self.viewBox = `0 0 ${max} ${max}`;
		console.log('max', max);
		console.log('min', min);
		console.log('self.viewBox', self.viewBox);

		console.log('self.iconPath', self.iconPath);
		console.log('path en duro', 'M149.9 349.1l-.2-.2-32.8-28.9 32.8-28.9c3.6-3.2 4-8.8.8-12.4l-.2-.2-17.4-18.6c-3.4-3.6-9-3.7-12.4-.4l-57.7 54.1c-3.7 3.5-3.7 9.4 0 12.8l57.7 54.1c1.6 1.5 3.8 2.4 6 2.4 2.4 0 4.8-1 6.4-2.8l17.4-18.6c3.3-3.5 3.1-9.1-.4-12.4zm220-251.2L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM256 51.9l76.1 76.1H256zM336 464H48V48h160v104c0 13.3 10.7 24 24 24h104zM209.6 214c-4.7-1.4-9.5 1.3-10.9 6L144 408.1c-1.4 4.7 1.3 9.6 6 10.9l24.4 7.1c4.7 1.4 9.6-1.4 10.9-6L240 231.9c1.4-4.7-1.3-9.6-6-10.9zm24.5 76.9l.2.2 32.8 28.9-32.8 28.9c-3.6 3.2-4 8.8-.8 12.4l.2.2 17.4 18.6c3.3 3.5 8.9 3.7 12.4.4l57.7-54.1c3.7-3.5 3.7-9.4 0-12.8l-57.7-54.1c-3.5-3.3-9.1-3.2-12.4.4l-17.4 18.6c-3.3 3.5-3.1 9.1.4 12.4z');
		// console.log(mdiIcons);
		//mdiAlert
      },
      immediate: true
    },
  },
  data: () => {
    return {
      iconPath: null,
      viewBox: null
    };
  },
  methods: {},
  beforeMount: function () {},
  mounted() {
    // this.observer = new IntersectionObserver(this.infiniteScroll)
  },
};
</script>
<style scoped>

</style>