import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
// const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
// const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

//Inicio
const Inicio = () => import('@/views/dashboard/Inicio')

const Productos = () => import('@/views/productos/Productos')
const ProductosForm = () => import('@/views/productos/ProductosForm')

const Login = () => import('@/views/usuarios/Login')

// const AddContrato = () => import('@/views/contratos/AddContrato')

const Edificios = () => import('@/views/edificios/Edificios');
const EdificiosForm = () => import('@/views/edificios/EdificiosForm');

const Items = () => import('@/views/items/Items');
const ItemsForm = () => import('@/views/items/ItemsForm');

const Usuarios = () => import('@/views/usuarios/Usuarios');
const UsuariosForm = () => import('@/views/usuarios/UsuariosForm');

const Marcas = () => import('@/views/marcas/Marcas')
const MarcaForm = () => import('@/views/marcas/MarcaForm')

const Locaciones = () => import('@/views/locaciones/Locaciones')
const LocacionesForm = () => import('@/views/locaciones/LocacionesForm')

const Etiquetas = () => import('@/views/etiquetas/Etiquetas');

const ListaCompras = () => import('@/views/listaCompras/ListaCompras');

Vue.use(Router)

const rutas = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
});

console.log(configRoutes());

rutas.beforeEach((to, from, next) => {
    // console.log('to', to);
    // console.log('requiereLogin', requiereLogin);
    // console.log('localStorage.usuario', localStorage.usuario);

    console.log('to', to);

    const requiereLogin = to.matched.some(item => {
        return (
            item.props !== undefined &&
            item.props.default !== undefined &&
            item.props.default.requireLogin !== undefined &&
            item.props.default.requireLogin == true
        );
    });

    if (requiereLogin && (localStorage.stock_usuario == undefined || localStorage.stock_usuario == null)) {
        next({ name: 'Login' });
    } else {
        if (to.name == 'Home')
            next({ name: 'Dashboard' });
        else
            next();
    }
})

export default rutas;

function configRoutes() {
    return [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/',
            props: {
                requireLogin: true
            },
            name: 'Home',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Inicio
                },
                {
                    path: 'lista-de-compras',
                    meta: { label: 'Lista de compras' },
                    component: ListaCompras,
                    children: [
                        {
                            path: 'yyy',
                            name: 'YYY Lista de compras',
                            component: ListaCompras
                        }
                    ]
                },
                {
                    path: 'marcas',
                    meta: { label: 'Marcas' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Marcas',
                            component: Marcas
                        },
                        {
                            path: 'nueva',
                            name: 'Nueva marca',
                            component: MarcaForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar marca',
                            component: MarcaForm
                        }
                    ]
                },
                {
                    path: 'locaciones',
                    meta: { label: 'Locaciones' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Locaciones',
                            component: Locaciones
                        },
                        {
                            path: 'nueva',
                            name: 'Nueva locación',
                            component: LocacionesForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar locación',
                            component: LocacionesForm
                        }
                    ]
                },
                {
                    path: 'etiquetas',
                    meta: { label: 'Etiquetas' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Etiquetas',
                            component: Etiquetas
                        },
                        {
                            path: 'nueva',
                            name: 'Nueva locación',
                            component: LocacionesForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar locación',
                            component: LocacionesForm
                        }
                    ]
                },
                {
                    path: 'edificios',
                    meta: { label: 'Edificios' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Edificios',
                            component: Edificios
                        },
                        {
                            path: 'nuevo',
                            name: 'Nuevo edificio',
                            component: EdificiosForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar edificio',
                            component: EdificiosForm
                        }
                    ]
                },
                {
                    path: 'items',
                    meta: { label: 'Ítems' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Ítems',
                            component: Items
                        },
                        {
                            path: 'nuevo',
                            name: 'Nuevo',
                            component: ItemsForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar',
                            component: ItemsForm
                        }
                    ]
                },
                {
                    path: 'usuarios',
                    meta: { label: 'Usuarios' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Usuarios',
                            component: Usuarios
                        },
                        {
                            path: 'nuevo',
                            name: 'Nuevo usuario',
                            component: UsuariosForm
                        },
                        {
                            path: 'editar/:id',
                            name: 'Editar usuario',
                            component: UsuariosForm
                        }
                    ]
                },
                {
                    path: 'productos',
                    meta: { label: 'Productos' },
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Productos',
                            component: Productos,
                            meta: {
                                requiresAdmin: false
                            }
                        },
                        {
                            path: 'nuevo',
                            meta: { label: 'Agregar producto' },
                            name: 'Agregar Producto',
                            component: ProductosForm,
                        },
                        {
                            path: 'editar/:id',
                            meta: { label: 'Editar producto' },
                            name: 'Editar Producto',
                            component: ProductosForm,
                            // meta: {
                            //   requiresAdmin: true
                            // }
                        }
                    ]
                }
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                // {
                //     path: 'login',
                //     name: 'Login',
                //     component: Login
                // },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        }
    ]
}

