import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome/index';
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import VueCurrencyFilter from 'vue-currency-filter'
import TruncateTextFilter from './filters/truncateText'
import moment from 'moment'
import ToggleButton from 'vue-js-toggle-button'
import VCalendar from 'v-calendar';
// import { TableColumn, TableComponent } from 'vue-table-component';
// import TableComponent from 'vue-table-component';
import VueToastr from "vue-toastr";
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';
import VSwitch from 'v-switch-case';

// import mainAxios from 'axios';
const axios = require('axios').default;
// const axiosScapper = require('axios').default;

import VueAxios from 'vue-axios';
import vSelect from 'vue-select';
import { BootstrapVue } from 'bootstrap-vue';
import Datepicker from 'vuejs-datepicker';
import PrettyCheck from 'pretty-checkbox-vue/check';
import { loadingBtnDirective, loadingDivDirective} from './utils/directives';
import VueLocalStorage from 'vue-localstorage';
import MyIcon from './components/MyIcon';
// import VueQuagga from 'vue-quaggajs';

// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import 'pretty-checkbox/src/pretty-checkbox.scss';
// import 'assets/scss/style.css';

// import {default as axiosScapper} from 'axios';
// var axiosScapper = require("axios");


Vue.config.performance = true
// Make BootstrapVue available throughout your project
Vue.use(VueLocalStorage);
Vue.use(BootstrapVue);
// Vue.use(VueQuagga);
Vue.use(VCalendar, {
    // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
    // ...,                // ...other defaults

});
// Vue.use(TableComponent);
Vue.use(CoreuiVue)
Vue.use(ToggleButton)

const axiosScapper = axios.create({
    baseURL: process.env.VUE_APP_URL_SCRAPING
});

// axiosScapper = { ...axiosScapper };
// axios = { ...axios };

// axios.defaults = { ...axios.defaults };
// axiosScapper.defaults = { ...axiosScapper.defaults };

axios.defaults.baseURL = process.env.VUE_APP_URL_API;
axiosScapper.defaults.baseURL = process.env.VUE_APP_URL_SCRAPING;

// console.log('axios', axios.defaults);
// console.log('axiosScapper', axiosScapper.defaults);

Vue.use(VueAxios, { axios, axiosScapper });
Vue.filter('truncate', TruncateTextFilter);
Vue.use(VueCurrencyFilter, {
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
});
Vue.use(VSwitch);
Vue.use(mdiVue, {
    icons: mdijs
}); 
Vue.use(VueToastr, {
    /* OverWrite Plugin Options if you need */
});
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.moment = moment
Vue.prototype.$apiAdress = process.env.VUE_APP_URL_API;

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-select', vSelect);
Vue.component('p-check', PrettyCheck);
Vue.component('my-icon', MyIcon);
// Vue.component('table-component', TableComponent);
// Vue.component('table-column', TableColumn);

Vue.config.productionTip = false;

Vue.directive('loading-btn', loadingBtnDirective);
Vue.directive('loading-div', loadingDivDirective);

// console.log(Datepicker);
new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App,
        FontAwesomeIcon,
        Datepicker
    },
    mounted() {
        axios.interceptors.response.use((response) => {
            if (response.data) {
                return response.data;
            } else {
                return response;
            }
        })

        axios.interceptors.request.use(config => {
            // console.log(config);
            //console.log(config)
            return config
        })
        axios.interceptors.request.use(response => {
            //console.log(response)

            // updated 20180406
            return response
        }, err => {
            return Promise.reject(err)
        });

        axiosScapper.interceptors.response.use((response) => {
            if (response.data) {
                return response.data;
            } else {
                return response;
            }
        })

        axiosScapper.interceptors.request.use(config => {
            // console.log(config);
            //console.log(config)
            return config
        })
        axiosScapper.interceptors.request.use(response => {
            //console.log(response)

            // updated 20180406
            return response
        }, err => {
            return Promise.reject(err)
        })
    }
})
