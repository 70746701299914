const loadingBtnDirective = function (el, binding) {
    const svg = el.getElementsByClassName("svg-inline--fa");
    if (binding.value.loading) {
        const span = document.createElement('span');
        span.className = 'spinner-border spinner-border-sm';
        span.setAttribute('aria-hidden', true);
        el.setAttribute("disabled", "disabled");
        el.prepend(span);
        if (svg.length > 0) {
            svg[0].classList.add("d-none");
        }
    } else {
        el.removeAttribute("disabled");
        const span = el.getElementsByTagName('span');
        if (span.length > 0) {
            span[0].remove();
        }

        if (svg.length > 0) {
            svg[0].classList.remove("d-none");
        }
    }
};

const loadingDivDirective = function (el, binding) {
    // const svg = el.getElementsByClassName("svg-inline--fa");

    console.log('binding.value', binding.value);

    if (binding.value.loading) {
        const div = document.createElement('div');
        const divContent = document.createElement('div');

        const spinner = document.createElement('div');
        const text = document.createElement('div');

        divContent.className = 'div-loading-content mt-1 mb-1';
        div.className = 'div-loading';
        spinner.className = 'lds-dual-ring';

        text.textContent = "Cargando";

        divContent.appendChild(spinner);
        divContent.appendChild(text);
        div.setAttribute('aria-hidden', true);
        // el.setAttribute("disabled", "disabled");
        div.append(divContent);
        el.prepend(div);
        // if (svg.length > 0) {
        //     svg[0].classList.add("d-none");
        // }
    } else {
        // el.removeAttribute("disabled");
        const div = el.getElementsByClassName('div-loading');
        if (div.length > 0) {
            div[0].remove();
        }

        // if (svg.length > 0) {
        //     svg[0].classList.remove("d-none");
        // }
    }
};

export { 
    loadingBtnDirective,
    loadingDivDirective
};